import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { getAllContentPages, getAllTeams, getAllTeamsOrdered, getContentPagesPage, getContentTeamsPage } from '../../store';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { PaginationComponent } from 'src/app/standalone/pagination/pagination.component';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';
import { CoreModule } from 'src/app/core/core.module';
import { TeamCardComponent } from '../../components/team-card/team-card.component';
import { VacancyCardComponent } from '../../components/vacancie-card/vacancy-card.component';

@Component({
  selector: 'app-teams-overview',
  standalone: true,
  imports: [RouterLink, CommonModule, PaginationComponent, TextUtilityModule, CoreModule, TeamCardComponent],
  templateUrl: './teams-overview.component.html',
  styleUrl: './teams-overview.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamsOverviewComponent {
  private store = inject(Store);

  content$ = this.store.selectSignal(getAllTeamsOrdered);
  page$ = this.store.selectSignal(getContentTeamsPage);
}
