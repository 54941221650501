import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { Store } from '@ngrx/store';
import { tap, filter, take, switchMap, catchError, withLatestFrom } from 'rxjs/operators';

import * as fromStore from '../store';
import { of } from 'rxjs';

export const TeamOverviewGuard: CanActivateFn = route => {
  const store = inject(Store);
  return store
    .select(fromStore.getContentTeamsOverviewLoaded)
    .pipe(
      withLatestFrom(store.select(fromStore.getContentTeamsPage), store.select(fromStore.getContentTeamsOverviewLoading)),
      tap(([loaded, page, loading]) => {
        const currentPage = +route.queryParams['page'] || 0;

        if ((!loaded || currentPage !== page.currentPage) && !loading) {
          store.dispatch(
            fromStore.LoadContentTeams({
              skip: currentPage * page.pageSize,
              take: page.pageSize,
            })
          );
        }
      }),
      filter(([loaded]) => loaded),
      take(1)
    )
    .pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
};
