<!-- Paginatie -->
<div class="text-center section-inset-y" *ngIf="page?.pageCount && page.pageCount > 1">
  <ul class="pagination d-flex">
    <li class="pagination__item">
      <button
        class="pagination__item__link"
        [routerLink]="[]"
        [disabled]="page.currentPage === 0"
        [class.is--disabled]="page.currentPage === 0"
        [queryParams]="{ page: page.currentPage - 1 }"
      >
        <span class="sr-only">Vorige Pagina</span>
        <fstr-icon class="fill-secondary" [anchor]="'icon-arrow-left'"></fstr-icon>
      </button>
    </li>
    <li class="pagination__item" *ngFor="let p of pages">
      <button
        class="pagination__item__link"
        [routerLink]="[]"
        [queryParams]="{ page: p }"
        [routerLinkActive]="'is--active'"
        [class.is--active]="p === 0"
      >
        <span class="sr-only">Pagina</span>
        {{ p + 1 }}
      </button>
    </li>
    <li class="pagination__item">
      <button
        class="pagination__item__link"
        [routerLink]="[]"
        [queryParams]="{ page: page.currentPage + 1 }"
        [disabled]="!(page.currentPage < page.pageCount - 1)"
        [class.is--disabled]="!(page.currentPage < page.pageCount - 1)"
      >
        <span class="sr-only">Volgende Pagina</span>
        <fstr-icon class="fill-secondary" [anchor]="'icon-arrow-right'"></fstr-icon>
      </button>
    </li>
  </ul>
</div>
