import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '@teamfoster/sdk/icon';
import { RouterModule } from '@angular/router';
import { ImageModule } from '@teamfoster/sdk/image';
import { ContentPage } from '../../models';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';

@Component({
  selector: 'app-vacancie-block-card',
  standalone: true,
  imports: [CommonModule, IconModule, RouterModule, ImageModule, TextUtilityModule, DictionaryNgrxModule],
  templateUrl: './vacancie-block-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VacancieBlockCardComponent {
  @Input({ required: true }) content!: ContentPage;
  @Input() showIntro = false;
}
