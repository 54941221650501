@if (page$(); as page) {
  <article class="content-page section-outset-b">
    <app-header [image]="page.image" [title]="page.title" [subtitle]="page.subtitle" [intro]="page.intro">
      @if (page.tags.length > 0) {
        <ul class="list-unstyled d-flex gap-2">
          @for (block of page.tags; track block.id; let i = $index) {
            <li class="mb-2">
              <span class="badge bg-light">{{ block.name }}</span>
            </li>
          }
        </ul>
      }
    </app-header>
    <main class="content-page__main">
      <div class="container mb-4">
        @if (page.text) {
          <div
            class="content-page__text markdown-content mb-6 mb-md-8"
            [class.text-light]="page.contentBlocks.length"
            [innerHTML]="page.text | resizeHtmlImages | safe"
            fstrDynamicContent
          ></div>
        }
      </div>
      <app-content-block-container [blocks]="page.contentBlocks"></app-content-block-container>
    </main>
  </article>
}
