<article class="section-inset-b py-md-5">
  <div class="container">
    <header class="mb-5">
      <h1 class="h1">Team</h1>
    </header>
    <main class="">
      <ul class="row list-unstyled">
        @for (page of content$(); track page.id) {
          <li class="col-md-4 col-lg-3 mb-4">
            <app-team-card [team]="page"></app-team-card>
          </li>
        }
      </ul>
      <app-pagination [page]="page$()"></app-pagination>
    </main>
  </div>
</article>
