import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { Store } from '@ngrx/store';
import { tap, take, switchMap, catchError, map, filter } from 'rxjs/operators';

import * as fromStore from '../store';
import { of } from 'rxjs';
import { SetTitle, SetTags } from '../../store';
import { StringUtility } from '@teamfoster/sdk/text-utility';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';

export const ContentPageMetaGuard: CanActivateFn = route => {
  const store = inject(Store);
  const lang = inject(LanguageService);
  const dict = new FromDictionaryPipe(lang);
  return store
    .select(fromStore.getSelectedContentPage)
    .pipe(
      tap(page => {
        if (!page) {
          return;
        }
        store.dispatch(
          SetTitle({
            title: `${page?.metaData?.title || page?.title} ${
              dict.transform('meta-titel-suffix') ? '- ' + dict.transform('meta-titel-suffix') : ''
            }`,
          })
        );
        store.dispatch(
          SetTags({
            properties: {
              'description': StringUtility.Truncate(StringUtility.StripHTML(page.metaData.description), 157, '...'),
              'og:image': page.metaData.imageUrl ? `https:${page.metaData.imageUrl}` : '',
              'og:title': `${page.metaData.title} ${dict.transform('meta-titel-suffix')}`,
              'og:description': StringUtility.Truncate(StringUtility.StripHTML(page.metaData.description), 157, '...'),
              'keywords': `${page.metaData.keywords?.join(',')}`,
            },
          })
        );
      }),
      filter(a => a?.id > 0),
      map(a => true),
      take(1)
    )
    .pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
};
