<a [routerLink]="['/vacancies', content.id, content.title | urlRewrite]" class="link-unstyled">
  <article class="content-card card">
    <figure class="card-img-top">
      @if (content.image && content.image.url) {
        <fstr-image
          [alt]="content.image.alt || content.title"
          class="img-fluid"
          [width]="450"
          [height]="440"
          [colSpan]="{ xs: 12, sm: 6 }"
          [image]="content.image"
          [format]="'jpg'"
        ></fstr-image>
        <!-- <img
          class="img-fluid"
          [src]="content.image.url + '?width=600&height=600&mode=crop&format=webp&scale=both'"
          [alt]="content.image.alt"
        /> -->
      } @else {
        <fstr-image
          [alt]="content.title"
          class="img-fluid"
          [width]="450"
          [height]="440"
          [colSpan]="{ xs: 12, sm: 6 }"
          [image]="{ url: 'vacancy-fallback-card-image' | fromDictionary }"
          [format]="'jpg'"
        ></fstr-image>
      }
    </figure>
    <main class="card-body px-0 d-flex flex-column">
      <header class="mb-3">
        <h2 class="card-title content-card__title mb-0">
          {{ content.title }}
        </h2>
        @if (content.intro) {
          <div class="card-text fs-body-xs mt-3">{{ content.intro | stripHtml | truncate: 300 }}</div>
        }
      </header>
      <footer class="mt-auto mb-0 pt-3">
        <a [routerLink]="['/vacancies', content.id, content.title | urlRewrite]" class="button button-icon button--dark">
          <fstr-icon anchor="icon-chevron-right"></fstr-icon>
        </a>
      </footer>
    </main>
  </article>
</a>
