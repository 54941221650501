import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { Store } from '@ngrx/store';
import { tap, filter, take, switchMap, catchError, withLatestFrom } from 'rxjs/operators';

import * as fromStore from '../store';
import { of } from 'rxjs';

export const ContentOverviewGuard: CanActivateFn = route => {
  const store = inject(Store);
  return store
    .select(fromStore.getContentPagesOverviewLoaded)
    .pipe(
      withLatestFrom(store.select(fromStore.getContentPagesPage), store.select(fromStore.getContentPagesOverviewLoading)),
      tap(([loaded, page, loading]) => {
        const currentPage = +route.queryParams['page'] || 0;
        const contentTypeId = +route.data['contentTypeId'] || 2;

        if ((!loaded || currentPage !== page.currentPage || contentTypeId !== page.filters['contentTypeId']) && !loading) {
          store.dispatch(
            fromStore.LoadContentOfType({
              skip: currentPage * page.pageSize,
              take: page.pageSize,
              contentTypeId,
            })
          );
        }
      }),
      filter(([loaded]) => loaded),
      take(1)
    )
    .pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
};
