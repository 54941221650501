import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContentRoutingModule } from './content-routing.module';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromContent from './store';
import { InViewModule } from '@teamfoster/sdk/in-view';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';
import { IconModule } from '@teamfoster/sdk/icon';
import { Html5VideoPlayerModule } from '@teamfoster/sdk/html5-video-player';
import { ContentPageComponent } from './containers/content-page/content-page.component';
import { CoreModule } from '../core/core.module';
import { PaginationComponent } from '../standalone/pagination/pagination.component';
import { ContentBlocksModule } from '../content-blocks/content-blocks.module';
import { HeaderComponent } from '../standalone/header/header.component';
import { CookieNgrxModule } from '@teamfoster/sdk/cookie-ngrx';
import { ImageModule } from '@teamfoster/sdk/image';

@NgModule({
  declarations: [ContentPageComponent],
  imports: [
    CommonModule,
    ContentRoutingModule,
    StoreModule.forFeature(fromContent.featureKey, fromContent.reducers, {
      metaReducers: fromContent.metaReducers,
    }),
    EffectsModule.forFeature(fromContent.effects),
    InViewModule,
    TextUtilityModule,
    Html5VideoPlayerModule,
    IconModule,
    ImageModule,
    InViewModule,
    CoreModule,
    ContentBlocksModule,
    PaginationComponent,
    HeaderComponent,
    CookieNgrxModule,
  ],
  exports: [],
})
export class ContentModule {}
