import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { getAllContentPages, getContentPagesPage, getOrderedPagesByDate } from '../../store';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { PaginationComponent } from 'src/app/standalone/pagination/pagination.component';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';
import { NewsCardComponent } from '../../components/news-card/news-card.component';
import { OverviewCardComponent } from '../../components/oveview-card/overview-card.component';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { HeaderComponent } from 'src/app/standalone/header/header.component';
import { IconModule } from '@teamfoster/sdk/icon';
import { VacancyCardComponent } from '../../components/vacancie-card/vacancy-card.component';
import { VacancieBlockCardComponent } from '../../components/vacancie-block-card/vacancie-block-cardcomponent';

@Component({
  selector: 'app-content-overview',
  standalone: true,
  imports: [
    RouterLink,
    CommonModule,
    PaginationComponent,
    TextUtilityModule,
    NewsCardComponent,
    OverviewCardComponent,
    DictionaryNgrxModule,
    HeaderComponent,
    IconModule,
    VacancyCardComponent,
    VacancieBlockCardComponent,
  ],
  templateUrl: './content-overview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentOverviewComponent {
  private store = inject(Store);
  private route = inject(ActivatedRoute);

  content$ = this.store.selectSignal(getOrderedPagesByDate);
  page$ = this.store.selectSignal(getContentPagesPage);

  typeContent$ = computed(() => this.route.snapshot.data['contentTypeId']);
}
