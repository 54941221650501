<section class="section-inset-b content-overview">
  <app-header [title]="'overview-title-' + typeContent$() | fromDictionary" [intro]="'overview-intro-' + typeContent$() | fromDictionary">
    @if ('overview-contact-link' + typeContent$() | fromDictionary: false) {
      <a [routerLink]="'overview-contact-link' + typeContent$() | fromDictionary" class="button button--dark mt-1">
        <span class="me-4">Contact</span>
        <fstr-icon anchor="icon-chevron-right"></fstr-icon>
      </a>
    }
  </app-header>
  <div class="container">
    <!-- <header class="mb-5">
      <h1 class="h1 text-light">
        {{ 'overview-title-' + typeContent$() | fromDictionary }}
      </h1>
    </header> -->
    <main class="mt-5 mt-md-7">
      <ul class="row content-overview__list">
        @for (page of content$(); track page.id; let i = $index) {
          <li
            class="content-overview__list__item mb-4 mb-md-5"
            [class.col-12]="i === 0"
            [class.col-md-6]="i > 0 && i < 3"
            [ngClass]="i > 2 ? 'col-6 col-md-4 col-xl-3' : ''"
          >
            @switch (page.contentTypeId) {
              @case (2) {
                <app-news-card [showIntro]="i < 3" [news]="page"></app-news-card>
              }

              @case (5) {
                <app-vacancie-block-card [showIntro]="i < 3" [content]="page"></app-vacancie-block-card>
              }

              @default {
                <app-overview-card [content]="page"></app-overview-card>
              }
            }
          </li>
        }
      </ul>
      <app-pagination [page]="page$()"></app-pagination>
    </main>
  </div>
</section>
