import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import * as selectors from '../../store/selectors/content-pages.selector';

@Component({
  selector: 'app-content-page',
  templateUrl: './content-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentPageComponent {
  private store = inject(Store);
  page$ = this.store.selectSignal(selectors.getSelectedContentPage);
}
