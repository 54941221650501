import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '@teamfoster/sdk/icon';
import { RouterModule } from '@angular/router';
import { PageData } from 'src/app/core/models';

@Component({
  selector: 'app-pagination',
  standalone: true,
  imports: [CommonModule, IconModule, RouterModule],
  templateUrl: './pagination.component.html',
  styleUrl: './pagination.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent {
  @Input({ required: true }) page!: PageData;

  get pages() {
    return Array((this.page?.pageCount || 0) + 1)
      .fill(0)
      .map((x, i) => i);
  }
}
